import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Conditional } from '@/components/common/Conditional'
import { Dropdown } from '@/components/common/DropDown'

import { LoadUrlModal } from '@/components/common/Modals/LoadURLModal'
import { AttachmentsModal } from '@/components/common/Modals/AttachmentsModal'
import { CompareModal } from '@/components/common/Modals/CompareModal'

import { useDocTypeObj } from '@/hooks/useDocType'

import { selectDocument } from '@/store/document.slice'
import {
	selectContent,
	selectCurrentMode,
	setCurrentConfig,
	setCurrentContent,
} from '@/store/editor.slice'
import { modalOpenState, selectFromXOne, selectRights } from '@/store/ui.slice'
import { ButtonWrap } from './LeftButtonWrap'

const record = [
	{
		class: 'd-none d-xl-flex',
		labels: [true, true],
	},
	{
		class: 'd-none d-lg-flex d-xl-none',
		labels: [false, false],
	},
]

export const LeftHeader = () => {
	const dispatch = useDispatch()

	const file = useSelector(selectDocument)
	const content = useSelector(selectContent)
	const rights = useSelector(selectRights)
	const currentMode = useSelector(selectCurrentMode)
	const fromXone = useSelector(selectFromXOne)

	const {
		Confirmation,
		PreConfirmation,
		ReadOnlyDocument,
		Chasing,
		Affirmation,
		PreMatching,
		FlowAdvice,
		TermSheet
		,
		/* FlowCorrespondence */ IssuingDocumentation,
	} = useDocTypeObj(file.documentType)

	const restore = () => {
		dispatch(setCurrentConfig({ mode: rights[1] || !file.isReadOnly ? 'defaultMode' : 'readOnly' }))
		// used to be dispatch(setCurrentConfig({ mode: rights[1] ? 'defaultMode' : 'readOnly' }))
		// but Preview mode allows to edit doc, just not save it
		dispatch(setCurrentContent(content ?? ''))
	}

	if (!rights[0]) {
		return null
	}

	const switchCompareLocal = (comp: boolean) =>
		comp ? 'header.button.exitcompare' : 'header.button.compare'

	return (
		<>
			<div className="d-flex col" style={{ alignItems: 'center' }}>
				<div className="d-flex">
					<LoadUrlModal />
					<AttachmentsModal />
					<CompareModal />

					<button
						type="button"
						className="btn sgbs-btn-default btn-lg"
						onClick={() =>
							dispatch(modalOpenState({ modalId: 'LoadURL', openState: true }))
						}
					>
						<i className="icon">folder_open</i>
					</button>
					<Conditional condition={file.tradeReference !== ''}>
						<div
							className={`d-flex flex-column ${
								file.documentType ? '' : 'justify-content-center'
							} mx-3`}
						>
							<span className="text-truncate mb-0">{file.tradeReference}</span>
							<h5 className="text-truncate mb-0">{file.documentType}</h5>
						</div>
					</Conditional>

					<>
						{record.map(r => (
							<div className={r.class} key={r.class}>
								<ButtonWrap
									fileInfos={file}
									setModal={
										currentMode === 'compareMode'
											? restore
											: () =>
													dispatch(
														modalOpenState({
															modalId: 'Compare',
															openState: true,
														}),
													)
									}
									icon="view_agenda"
									styleIcon={{ transform: 'rotate(90deg)' }}
									label={
										r.labels[0]
											? switchCompareLocal(currentMode === 'compareMode')
											: undefined
									}
									condition={
										fromXone &&
										(Confirmation ||
											ReadOnlyDocument ||
											FlowAdvice ||
											IssuingDocumentation ||
											PreMatching ||
											TermSheet)
									}
								/>
								<ButtonWrap
									fileInfos={file}
									setModal={() =>
										dispatch(
											modalOpenState({
												modalId: 'Attachment',
												openState: true,
											}),
										)
									}
									icon="attach_file"
									label={r.labels[1] ? 'header.button.attachments' : undefined}
									condition={
										fromXone &&
										file?.fileName !== undefined &&
										(PreConfirmation || Chasing || Affirmation || PreMatching)
									}
								/>
							</div>
						))}

						<div className="d-flex d-lg-none">
							<Conditional
								condition={
									(fromXone &&
										(Confirmation ||
											ReadOnlyDocument ||
											FlowAdvice ||
											IssuingDocumentation ||
											PreMatching ||
											TermSheet)) ||
									(fromXone &&
										file?.fileName !== undefined &&
										(PreConfirmation || Chasing || Affirmation || PreMatching || TermSheet))
								}
							>
								<Dropdown
									onChange={e => e}
									useDropeNameAsTitle
									hideArrow
									dropName={<i className="icon">more_vert</i>}
									values={
										[
											fromXone &&
											(Confirmation ||
												ReadOnlyDocument ||
												FlowAdvice ||
												IssuingDocumentation ||
												PreMatching ||
												TermSheet )
												? [
														'compare',
														<ButtonWrap
															fileInfos={file}
															setModal={
																currentMode === 'compareMode'
																	? restore
																	: () =>
																			dispatch(
																				modalOpenState({
																					modalId:
																						'Compare',
																					openState: true,
																				}),
																			)
															}
															icon="view_agenda"
															styleIcon={{
																transform: 'rotate(90deg)',
															}}
															label={switchCompareLocal(
																currentMode === 'compareMode',
															)}
															condition
														/>,
														true,
												  ]
												: undefined,
											fromXone &&
											file?.fileName !== undefined &&
											(PreConfirmation ||
												Chasing ||
												Affirmation ||
												PreMatching )
												? [
														'attachments',
														<ButtonWrap
															fileInfos={file}
															setModal={() =>
																dispatch(
																	modalOpenState({
																		modalId: 'Attachment',
																		openState: true,
																	}),
																)
															}
															icon="attach_file"
															label={switchCompareLocal(
																currentMode === 'compareMode',
															)}
														/>,
														true,
												  ]
												: undefined,
										].filter(arr => arr) as unknown as string[]
									}
								/>
							</Conditional>
						</div>
					</>
				</div>
			</div>
		</>
	)
}
