import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { documentApi } from './document.api'
import { documentSlice } from './document.slice'
import { editorSlice } from './editor.slice'
import { presentationApi } from './presentation.api'
import { UISlice } from './ui.slice'
import { userApi } from './user.api'
import { toastMiddleware } from './toast.middleware'

export const store = configureStore({
	reducer: {
		document: documentSlice.reducer,
		editor: editorSlice.reducer,
		ui: UISlice.reducer,
		[documentApi.reducerPath]: documentApi.reducer,
		[presentationApi.reducerPath]: presentationApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat(toastMiddleware)
			.concat(documentApi.middleware)
			.concat(presentationApi.middleware)
			.concat(userApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch<AppDispatch>
