import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { modalOpenState, selectModal } from '@/store/ui.slice';
import { addChasingAttachments, removeChasingAttachments, selectChasingAttachment } from '@/store/document.slice';
import { Conditional } from '../Conditional';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

export const AttacherModal = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  const attachments = useSelector(selectChasingAttachment);
  const open = useSelector(selectModal('Attacher'));

  const dispatch = useDispatch();

  const { sgwtWebAnalytics } = useSgwtWidgets();

  useEffect(() => {
    if (open) {
      sgwtWebAnalytics?.trackEvent('Interaction', 'Opened attachments modal');
    }
  }, [open, sgwtWebAnalytics]);

  const openFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const openFileChoosen = () => {
    if (inputFile?.current?.files?.[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target?.result as ArrayBuffer;
        const name = inputFile?.current?.files?.[0].name;
        if (name && text) {
          dispatch(
            addChasingAttachments({
              key: name,
              value: new TextDecoder('utf-8').decode(text) as unknown as ArrayBuffer,
            }),
          );
          sgwtWebAnalytics?.trackEvent('Action', 'Document loaded from PC');
        }
        if (inputFile?.current?.files?.[0]) {
          inputFile.current.value = '';
        }
      };
      reader.readAsArrayBuffer(inputFile.current.files[0]);
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="modal-title">
              <FormattedMessage id="modal.chasingAttachments.title" />
            </h3>
            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              onClick={() => dispatch(modalOpenState({ modalId: 'Attacher', openState: false }))}
            >
              close
            </button>
          </div>
          <div className="modal-body pb-0">
            <FormattedMessage id="modal.chasingAttachments.text" />
            <Conditional condition={attachments.length > 0}>
              <ul className="list-group mt-4 bg-lvl2">
                {attachments.map((f) => (
                  <li className="list-group-item" key={f.key}>
                    {f.key}
                    <button
                      type="button"
                      className="close icon"
                      data-dismiss="modal"
                      onClick={() => dispatch(removeChasingAttachments(f.key))}
                    >
                      close
                    </button>
                  </li>
                ))}
              </ul>
            </Conditional>
          </div>
          <div className="modal-footer p-4">
            <div className="btn-group m-0">
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary m-0 mr-1"
                data-dismiss="modal"
                onClick={() => dispatch(modalOpenState({ modalId: 'Attacher', openState: false }))}
              >
                <FormattedMessage id="modal.chasingAttachments.cancel" />
              </button>
              <button type="button" className="btn btn-lg btn-info m-0" data-dismiss="modal" onClick={openFile}>
                <FormattedMessage id="modal.chasingAttachments.addAttachment" />
              </button>
            </div>
          </div>
        </div>
        <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onInput={openFileChoosen} />
      </div>
    </Modal>
  );
};
