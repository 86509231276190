import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Conditional } from '@/components/common/Conditional'
import { FileInfo } from '@/store/document.slice'
import { isFileLoaded } from '../common/isFileLoaded'

interface IButtonWrapProps {
	fileInfos: FileInfo
	setModal: (a: boolean) => void
	icon: string
	styleIcon?: React.CSSProperties
	label?: string
	condition?: boolean
}
const baseClassBtn = 'btn btn-flat-secondary btn-lg'
export const ButtonWrap = ({
	fileInfos,
	setModal,
	icon,
	styleIcon,
	label,
	condition,
}: IButtonWrapProps) => (
	<Conditional condition={condition ?? true}>
		<button
			disabled={!isFileLoaded(fileInfos)}
			type="button"
			className={`${baseClassBtn}${label ? ' btn-icon-text ' : ''}${
				!isFileLoaded(fileInfos) ? 'disabled' : ''
			}`}
			onClick={() => setModal(true)}
		>
			<i className="icon" style={styleIcon}>
				{icon}
			</i>
			<Conditional condition={label !== undefined}>
				<FormattedMessage id={label} />
			</Conditional>
		</button>
	</Conditional>
)
