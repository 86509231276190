import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { modalOpenState, selectModal } from '@/store/ui.slice';
import { selectDocument } from '@/store/document.slice';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

export const AttachmentsModal = () => {
  const file = useSelector(selectDocument);

  const [attachments, setAttachments] = React.useState<string[]>([file.fileName ?? '']);
  const dispatch = useDispatch();
  const open = useSelector(selectModal('Attachment'));

  const { sgwtWebAnalytics } = useSgwtWidgets();

  useEffect(() => {
    if (open) {
      sgwtWebAnalytics?.trackEvent('Interaction', 'Opened attachments modal');
    }
  }, [open, sgwtWebAnalytics]);

  useEffect(() => {
    setAttachments([file.fileName ?? '']);
  }, [file.fileName]);

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="modal-title">
              <FormattedMessage id="modal.attachments.title" />
            </h3>
            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              onClick={() => dispatch(modalOpenState({ modalId: 'Attachment', openState: false }))}
            >
              close
            </button>
          </div>
          <div className="modal-body pb-0">
            <FormattedMessage id="modal.attachments.text" />
            <ul className="list-group mt-4 bg-lvl2">
              {attachments.map((f) => (
                <li className="list-group-item" key={f}>
                  {f}
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-dismiss="modal"
              onClick={() => dispatch(modalOpenState({ modalId: 'Attachment', openState: false }))}
            >
              <FormattedMessage id="modal.attachments.confirm" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
