import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectChasing, selectChasingAttachment } from '@/store/document.slice'
import { isPlainChasingInfos } from '@/types/fileInfos'
import { Conditional } from '@/components/common/Conditional'
import { ButtonWrap, IRecord } from '../common/RightButtonWrap'
import { modalOpenState } from '@/store/ui.slice'
import { useAppDispatch } from '@/store/store'
import { useLazySaveChasingQuery, useLazySendChasingQuery } from '@/store/document.api'
import { selectEditor } from '@/store/editor.slice'
import { UserContext } from '@/context/UserContext'

export const ChasingButtons = ({ values }: { values: IRecord }) => {
	const dispatch = useAppDispatch()
	const chasing = useSelector(selectChasing)
	const attachedFiles = useSelector(selectChasingAttachment)

	const editor = useSelector(selectEditor)

	const { sgConnect } = useContext(UserContext).globalContext

	const [triggerSave, saveResponse] = useLazySaveChasingQuery()
	const [triggerSend, sendResponse] = useLazySendChasingQuery()

	return (
		<Conditional condition={isPlainChasingInfos(chasing)}>
			<ButtonWrap
				condition
				disabled={false}
				action={() => dispatch(modalOpenState({ modalId: 'Attacher', openState: true }))}
				icon="save"
				label={values.labels[1] ? 'header.button.chasing.attach' : undefined}
				mr={values.mr[0]}
			/>

			<ButtonWrap
				condition
				disabled={!saveResponse.isUninitialized || !sendResponse.isUninitialized}
				action={async () => {
					const currentText = (await editor?.saveToString())?.draftXML

					if (!currentText || !isPlainChasingInfos(chasing)) {
						return
					}

					triggerSave({
						...chasing,
						workingDocument: currentText,
						userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
					})
				}}
				icon="save"
				label={
					values.labels[1]
						? `header.button.chasing.save${saveResponse.isFetching ? 'ing' : ''}`
						: undefined
				}
				mr={values.mr[0]}
			/>
			<ButtonWrap
				condition
				disabled={false}
				action={async () => {
					const currentText = (await editor?.saveToString())?.draftXML

					if (!currentText || !isPlainChasingInfos(chasing)) {
						return
					}

					triggerSend({
						...chasing,
						attachedFiles,
						workingDocument: currentText,
						actionComment: '',
						userProfile: '',
						userBdrInitials: sgConnect?.getIdTokenClaims()?.sub.split('@')[0],
					})
				}}
				icon="send"
				label={
					values.labels[1]
						? `header.button.chasing.save_send${sendResponse.isFetching ? 'ing' : ''}`
						: undefined
				}
				btnType="btn-info"
			/>
		</Conditional>
	)
}
