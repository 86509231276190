import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createToast } from '@/components/common/ToasterProvider';
import { locales } from '@/context/IntlContext';
import { ToastMessage } from '@/types/toast';

export const fallBack = (
  base: string,
  func: string,
  code: number | string,
  source: string[] = Object.keys(locales.en),
) =>
  [
    `${base}.${func}.${code}`,
    `${base}.${func}.${Math.floor(Number(code) / 100)}xx`,
    `${base}.${func}.${Number(code) >= 400 ? 'ko' : 'ok'}`,
  ].reduce(
    (acc: string | null, value: string) => (acc === null && source.includes(value) ? value : acc),
    null,
  ) ?? `${base}.${func}.no_code`;

export const pushError = (
  id: string,
  values?: Record<string, React.ReactNode>,
  opts?: { level?: ToastMessage['level']; icon?: string; type?: ToastMessage['type'] },
) =>
  createToast({
    text: <FormattedMessage id={id} values={values} />,
    level: opts?.level ?? 'danger',
    icon: opts?.icon ?? 'error_outline',
    type: opts?.type ?? 'alert',
  });

export const pushSuccess = (
  id: string,
  values?: Record<string, React.ReactNode>,
  opts?: { level?: ToastMessage['level']; icon?: string; type?: ToastMessage['type'] },
) =>
  createToast({
    text: <FormattedMessage id={id} values={values} />,
    level: opts?.level ?? 'info',
    icon: opts?.icon ?? 'info_outline',
    type: opts?.type,
  });

export const rapidToast =
  (base: string, src: string[] = Object.keys(locales.en)) =>
  (func: string) =>
  (
    code: number | string,
    values?: Record<string, React.ReactNode>,
    opts?: { level?: ToastMessage['level']; icon?: string; type?: ToastMessage['type'] },
  ) => {
    if (typeof code === 'string') {
      return pushError(fallBack(base, func, 'no_code', src), values, opts);
    }
    return code >= 400
      ? pushError(fallBack(base, func, code, src), values, opts)
      : pushSuccess(fallBack(base, func, code, src), values, opts);
  };
