import { FormattedMessage } from 'react-intl';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import type { DocumentCompareInfos } from '@/types/documentCompare';
import { modalOpenState, selectModal } from '@/store/ui.slice';
import { selectDocument } from '@/store/document.slice';
import { setCompareState } from '@/store/editor.slice';
import { useLazyGetDocumentCompareQuery } from '@/store/document.api';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useEffect, useState } from 'react';

interface IPropsRowDisplay {
  docId: string;
  version: number;
  eventType: string;
  date: Date;
  creator: string;
  tradeRef: string;
  removeFromSelection: () => void;
  addToSelection: () => void;
  selected: boolean;
}

const RowDisplay = ({ ...props }: IPropsRowDisplay) => (
  <tr>
    <th scope="row">
      <div
        role="checkbox"
        tabIndex={0}
        aria-checked={props.selected}
        className="custom-control custom-checkbox"
        onClick={props.selected ? props.removeFromSelection : props.addToSelection}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          readOnly
          checked={props.selected}
          id="customControlAutosizing"
        />
        <label className="custom-control-label" />
      </div>
    </th>
    <td>{props.tradeRef}</td>
    <td>{props.docId}</td>
    <td className="text-center">{props.version}</td>
    <td>{props.eventType}</td>
    <td>{format(props.date, 'dd MMM y - H:mm O')}</td>
    <td>{props.creator}</td>
  </tr>
);

export interface IPropsLoadUrlModal {
  setCompare: (a: boolean) => void;
}

export const CompareModal = () => {
  const [selected, setSelected] = useState<DocumentCompareInfos[]>([]);

  const dispatch = useDispatch();

  const file = useSelector(selectDocument);
  const open = useSelector(selectModal('Compare'));

  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [trigger, resp] = useLazyGetDocumentCompareQuery();

  useEffect(() => {
    if (open) {
      sgwtWebAnalytics?.trackEvent('Interaction', 'Opened compare modal');
    }
  }, [open, sgwtWebAnalytics]);

  useEffect(() => {
    if (!open) {
      return;
    }

    trigger({
      ...file,
      silent: true,
    });
  }, [file, open, trigger]);

  const addToSelection = (doc: DocumentCompareInfos) =>
    selected.length < 2 ? setSelected([...selected, doc]) : undefined;
  const removeFromSelection = (doc: DocumentCompareInfos) => setSelected(selected.filter((d) => d !== doc));

  const launchCompare = () => {
    const a = selected[0].workingContent === '' ? selected[0].originalContent : selected[0].workingContent;
    const b = selected[1].workingContent === '' ? selected[1].originalContent : selected[1].workingContent;

    const [contentSrc, contentDest] = [
      decodeURIComponent(escape(atob(a))).trim(),
      decodeURIComponent(escape(atob(b))).trim(),
    ];

    dispatch(setCompareState({ contentSrc, contentDest }));
    dispatch(modalOpenState({ modalId: 'Compare', openState: false }));
  };

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ maxWidth: '1024px' }}>
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <h3 className="modal-title">
              <FormattedMessage id="modal.compare.title" />
            </h3>
            <button
              type="button"
              className="close icon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(modalOpenState({ modalId: 'Compare', openState: false }))}
            >
              close
            </button>
          </div>

          <div className="modal-body" style={{ height: '512px' }}>
            <div className="sticky-top bg-lvl3" />

            {resp.isLoading || resp.isUninitialized || resp.isFetching ? (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  className="spinner-border spinner-border-sm"
                  style={{ height: '3rem', width: '3rem' }}
                  role="status"
                />
              </div>
            ) : (
              <>
                <div>
                  <FormattedMessage id="modal.compare.text" />
                </div>
                <table className="table table-striped table-hover mt-4">
                  <thead className="sticky-top bg-lvl1 shadow-lg">
                    <tr>
                      <th scope="col">#</th>
                      {['trade_id', 'id', 'version', 'event_type', 'creation_date', 'creator'].map((id) => (
                        <th aria-label={id} scope="col" key={id}>
                          <FormattedMessage id={`modal.compare.${id}`} />
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {resp.data?.items
                      .filter((doc) => (doc.workingContent === '' ? doc.originalContent : doc.workingContent))
                      .map((doc) => (
                        <RowDisplay
                          key={doc.documentId + doc.documentVersion + doc.modifiedOrGenerationDateTime}
                          docId={doc.documentId}
                          eventType={doc.eventType === 'Null' ? '' : doc.eventType}
                          version={Number(doc.documentVersion)}
                          date={parseISO(doc.modifiedOrGenerationDateTime)}
                          creator={doc.user}
                          tradeRef={file.tradeReference ?? ''}
                          addToSelection={() => addToSelection(doc)}
                          removeFromSelection={() => removeFromSelection(doc)}
                          selected={selected.reduce((acc: boolean, d) => (acc ? true : doc === d), false)}
                        />
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>

          <div className="modal-footer pt-4">
            <button
              type="button"
              className="btn btn-xl btn-flat-secondary"
              data-dismiss="modal"
              onClick={() => dispatch(modalOpenState({ modalId: 'Compare', openState: false }))}
            >
              <FormattedMessage id="modal.compare.close" />
            </button>
            <button
              type="button"
              className="btn btn-xl btn-primary"
              disabled={selected.length !== 2}
              data-dismiss="modal"
              onClick={launchCompare}
            >
              <FormattedMessage id="modal.compare.confirm" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
