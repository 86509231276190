export interface UrlFileInfos {
	docId?: string
	tradeRef?: string
	docType?: string
	lang?: string
	assetClass?: string
	isReadOnly: string
}

export interface UrlSgDocPreviewInfo {
	sgDocsId: string
}

export interface UrlSgDocComparatorInfo {
	sgDocsId: string
	tradeRef: string
	docType: string
}
export interface UrlChasingInfos {
	codent?: string
	codtrs?: string
	chasing?: string
	chasingDate?: string
	client?: string
}

export const isUrlChasingInfos = (
	infos: Readonly<Partial<UrlFileInfos | UrlChasingInfos>>,
): infos is Readonly<Partial<UrlChasingInfos>> => (infos as UrlChasingInfos).codent !== undefined

export interface FileInfos {
	docId?: string
	tradeRef?: string
	docType?: string
	lang?: string
	assetClass?: string
	fileName?: string
	fromPc?: boolean
	isReadOnly?: boolean
}

export type ChasingInfo = {
	documentType?: 'CHASING'
	codent?: string
	codtrs?: number
	chasing?: string
	chasingDate?: string
	client?: number
}

export const isPlainChasingInfos = (infos: ChasingInfo): infos is Required<ChasingInfo> =>
	infos.codent !== undefined &&
	infos.codtrs !== undefined &&
	infos.chasing !== undefined &&
	infos.chasingDate !== undefined &&
	infos.client !== undefined
