import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { Provider } from 'react-redux';

import { App } from '@/components/App';
import { SgwtConnectHTMLElement } from '@/types/sgwt-widgets';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { logger } from './helpers/logger';
import { store } from './store/store';
import { getConfig } from '@/config/config';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';

const setupSgwtConnectWidget = (sgwtConnectLocal: SGWTConnectCore) => {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnectLocal);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      widget.setSgwtConnectInstance(sgwtConnectLocal);
    }
  }
};

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  const {
    matomo: { id, url },
    sgwt_env,
  } = getConfig();

  root.render(
    <StrictMode>
      <SgwtWidgetContextProvider infrastructure="azure">
        <SgwtWebAnalytics siteId={id.toString()} baseUrl={url} debug={sgwt_env === 'homologation'} />
        <Provider store={store}>
          <App sgConnect={sgwtConnect} />
        </Provider>
      </SgwtWidgetContextProvider>
    </StrictMode>,
  );
};

const renderError = (authorizationError: SGWTConnectError) => {
  document.body.innerHTML = `
	<div class='alert alert-danger' role='alert'>
		Authorization error: ${authorizationError}.
	</div>`;
};

setupSgwtConnectWidget(sgwtConnect);

if (sgwtConnect.isAuthorized()) {
  renderApp();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError !== null) {
    logger.logError('SGWT: Authorization failed with error {errorMessage_s}', authorizationError);
    renderError(authorizationError);
  } else {
    sgwtConnect.requestAuthorization();
  }
}

sgwtConnect.on('authorizationDiscarded', () => {
  logger.logInformation('SGWT: Token is no longer available on the client side.');
});

sgwtConnect.on('authorizationExpired', () => {
  logger.logInformation('SGWT: Token is no longer valid.');
});

sgwtConnect.on('renewAuthorizationError', (error) => {
  logger.logError('SGWT: Failed to renew the token due to error {errorMessage_s}', error);
});

sgwtConnect.on('renewAuthorizationSuccess', () => {
  logger.logInformation('SGWT: A fresh token has been acquired.');
});
