import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadSmartDxEditor } from '@/components/routes/Editor/SgDocs/useLoadSmartDxEditor';
import { UrlSgDocComparatorInfo } from '@/types/fileInfos';
import { useGetDocumentQuery, useGetSgDocDocumentQuery } from '@/store/document.api';
import { selectEditor, setCompareState } from '@/store/editor.slice';
import { LoadingRenderComponent } from '@/components/common/LoadingRenderComponent';
import { ConcurencyModal } from '@/components/common/Modals/ConcurencyModal';
import { EditorHeader } from '@/components/routes/Editor/Xone/Header';
import { EditorAnchor } from '@/helpers/SmartDXWrap';

const SgDocsComparator = () => {
  useLoadSmartDxEditor([true, false, true, true]);
  const { sgDocsId, tradeRef, docType } = useParams<Readonly<UrlSgDocComparatorInfo>>();
  const { data: sgDocContent } = useGetSgDocDocumentQuery({ documentId: sgDocsId });
  const { data: xoneDocData } = useGetDocumentQuery({
    tradeReference: tradeRef ?? '',
    documentType: docType ?? '',
    documentId: 'null',
    isXone: true,
  });

  const editor = useSelector(selectEditor);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (sgDocContent && xoneDocData && editor) {
      let xoneDocContent;

      // we need to handle both case as the content sent back might need to be decoded
      try {
        xoneDocContent = decodeURIComponent(escape(atob(xoneDocData.value!))).trim();
      } catch (e) {
        xoneDocContent = atob(xoneDocData.value!).trim();
      }
      dispatch(
        setCompareState({
          contentSrc: xoneDocContent,
          contentDest: sgDocContent,
        }),
      );
    }
  }, [dispatch, sgDocContent, editor, xoneDocData]);

  return (
    <>
      <div className="container-fluid px-3 px-lg-4 pb-2 pb-lg-3 border-bottom">
        <div className="row justify-content-start">
          <LoadingRenderComponent isLoading={!editor}>
            <ConcurencyModal />
            <EditorHeader />
          </LoadingRenderComponent>
        </div>
      </div>
      <div>
        <EditorAnchor editorIsLoading={!editor} />
      </div>
    </>
  );
};

export default SgDocsComparator;
