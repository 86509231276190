import { ChangeEventHandler, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@/components/common/Modals/Modal';

import { modalOpenState, selectLoading, selectModal } from '@/store/ui.slice';
import { selectDocument } from '@/store/document.slice';
import { AppDispatch } from '@/store/store';
import { selectEditor } from '@/store/editor.slice';
import { saveNsendDocument } from '@/store/document.thunk';

export const isSettlementRelatedDocument = (docType: string) =>
  // settlement related documents : Prematching, Fixing, Payment, Payment remainder
  // https://sgithub.fr.world.socgen/XOne/XOne/blob/PROD60/src/Framework/TOne/Correspondence/Data/Enums/DocumentType.cs
  ['PreMatching', 'FlowAdvice', 'FlowCorrespondence', 'Fixing', 'Payment', 'PaymentReminder']
    .map((t) => t.toLowerCase())
    .includes(docType.toLowerCase());

export const isIssuingRelatedDocument = (docType: string) =>
  [
    'AccountingLetter',
    'AdmissionLetter',
    'DecisionLetter',
    'MarketingMaterial',
    'Notice',
    'RegReportBAS100',
    'RegReportBAS110',
    'TermSheet',
    'FinalTerms',
  ]
    .map((t) => t.toLowerCase())
    .includes(docType.toLowerCase());

export const getToastLocal = (isSettlement: boolean, isIssuing: boolean) =>
  isSettlement ? 'putSettlement' : isIssuing ? 'putIssuing' : 'putAction'; // eslint-disable-line

export const selectLocal = (doctype?: string) => {
  let local = '';

  switch (doctype) {
    case 'FlowCorrespondence': {
      local = '_flow_correspondence';
      break;
    }
    case 'Affirmation': {
      local = '_affirmation';
      break;
    }
    case 'PreConfirmation': {
      local = '_preconfirmation';
      break;
    }
    case 'FinalTerms': {
      local = '_termsheet';
      break;
    }
    case 'TermSheet': {
      local = '_termsheet';
      break;
    }
    default: {
      if (isIssuingRelatedDocument(doctype ?? '')) {
        local = '_issuing';
      }
      break;
    }
  }

  return (loading?: boolean) => `modal.send.save_send${loading ? 'ing' : ''}${local}`;
};

export const SaveAndSendModal = ({ draftXmlDocument }: { draftXmlDocument: string | undefined }) => {
  const [comment, setComment] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const open = useSelector(selectModal('SaveNSend'));
  const file = useSelector(selectDocument);
  const loading = useSelector(selectLoading('SaveNSend'));
  const editor = useSelector(selectEditor);

  const saveNsend = useCallback(async () => {
    const { assetClass, tradeReference, documentId, documentType } = file;
    const isIssuing = isIssuingRelatedDocument(documentType);
    const hasFileInfos = assetClass !== '' && tradeReference !== '' && documentId && documentType !== '';

    const editorDocument = await editor?.saveToString();
    const draftXmlDocumentToSend = editorDocument?.draftXML ?? draftXmlDocument;

    if (hasFileInfos && draftXmlDocumentToSend) {
      dispatch(
        saveNsendDocument({
          assetClass,
          tradeReference,
          documentId,
          documentType,
          workingDocument: draftXmlDocumentToSend,
          actionName: isIssuing ? 'Validate' : 'Send',
          actionComment: comment,
        }),
      );
    }
  }, [file, editor, draftXmlDocument, dispatch, comment]);

  const onCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.currentTarget.value !== comment) {
      setComment(event.currentTarget.value);
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content shadow-max">
          <div className="modal-header d-flex align-items-center p-2">
            <h3 className="modal-title pl-3 pt-2">
              <FormattedMessage id={selectLocal(file.documentType)(false)} />
            </h3>
            <button
              type="button"
              className="btn btn-xl btn-flat-secondary ml-auto"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(modalOpenState({ modalId: 'SaveNSend', openState: false }))}
            >
              <i className="icon">close</i>
            </button>
          </div>
          <div className="modal-body px-4 pb-0">
            <div>
              <FormattedMessage id="modal.send.text" />
            </div>
            <div className="mt-3">
              <label className="text-secondary" htmlFor="modal.send.comment">
                <FormattedMessage id="modal.send.comment" />
              </label>
              <textarea
                className="form-control form-control-lg"
                id="modal.send.comment"
                placeholder={intl.formatMessage({
                  id: 'modal.send.comment_placeholder',
                })}
                onChange={onCommentChange}
                value={comment}
              />
            </div>
          </div>
          <div className="modal-footer p-4">
            <div className="btn-group m-0">
              <button
                type="button"
                className="btn btn-lg btn-flat-secondary m-0 mr-1"
                data-dismiss="modal"
                onClick={() => dispatch(modalOpenState({ modalId: 'SaveNSend', openState: false }))}
              >
                <FormattedMessage id="modal.send.cancel" />
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-info m-0 ${loading ? 'disabled' : ''}`}
                data-dismiss="modal"
                onClick={saveNsend}
              >
                <FormattedMessage id={selectLocal(file.documentType)(loading)} />
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm mr-1"
                    style={{ height: '1rem', width: '1rem' }}
                    role="status"
                  />
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
