import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Conditional } from '@/components/common/Conditional'

export interface IRecord {
	class: string
	labels: [boolean, boolean, boolean, boolean]
	mr: [number, number]
}

export const record: IRecord[] = [
	{
		class: 'd-none d-xl-flex',
		labels: [true, true, true, true],
		mr: [2, 2],
	},
	{
		class: 'd-none d-md-flex d-xl-none',
		labels: [false, false, false, true],
		mr: [1, 2],
	},
	{
		class: 'd-flex d-md-none',
		labels: [false, false, false, false],
		mr: [1, 1],
	},
]

interface IButtonWrapProps {
	disabled: boolean
	loading?: boolean
	icon: string
	action: () => void
	label?: string
	loadingLabel?: string
	btnType?: string
	mr?: number
	condition: boolean
}
export const ButtonWrap = ({
	condition,
	disabled,
	loading,
	icon,
	action,
	label,
	loadingLabel,
	btnType,
	mr,
}: IButtonWrapProps) => (
	<Conditional condition={condition}>
		<button
			disabled={disabled || loading}
			type="button"
			className={`btn ${btnType ?? 'sgbs-btn-default'} btn-lg ${
				label ? 'btn-text-icon' : ''
			} mr-${mr ?? 0} ${disabled || loading ? 'disabled' : ''}`}
			onClick={action}
		>
			{!loading && label ? <FormattedMessage id={label} /> : null}
			{loading && loadingLabel ? <FormattedMessage id={loadingLabel} /> : null}
			{loading ? (
				<div
					className="spinner-border spinner-border-sm mr-1"
					style={{ height: '1rem', width: '1rem' }}
					role="status"
				/>
			) : (
				<i className="icon">{icon}</i>
			)}
		</button>
	</Conditional>
)
