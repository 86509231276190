import React from 'react'
import { Combinators } from '@bhc/ts-tools'

import type { SmartCommsTypes, SmartEditor } from '@/types/SmartCommsApi'

export type Perms = [boolean, boolean, boolean, boolean]
export type { SmartEditor }

// 184.7.185.45/smartcomms

export const loadSmartDxDraftEditorLibrary = (scriptSrc: string) =>
	new Promise<void>((resolve, reject) => {
		const existingScript = document.getElementById('smartDxDraftEditor')

		if (!existingScript) {
			const script = document.createElement('script')

			script.src = scriptSrc
			script.id = 'smartDxDraftEditor'

			script.onload = () => resolve()
			script.onabort = () => reject(new Error('aborted'))
			script.onerror = () => reject(new Error('error'))

			document.body.appendChild(script)

			return
		}

		resolve()
	})

export const smartDxConfig = (
	permissions: Perms,
	{ authUrl, url }: { clourdUrl: string; authUrl: string, url: string },
): SmartCommsTypes['DraftEditorProperties'] => ({
	server: url as SmartCommsTypes['DraftEditorProperties']['server'],
	clientServer:
		`${window.location.protocol}//${window.location.host}` as SmartCommsTypes['DraftEditorProperties']['clientServer'],
	targetElementID: 'draftEditorPlaceHolder',
	authUrl,
	authParams: `samlIdpLoginPopup=true&targetURL=${url
		}/smartcomms/draft-editor/draft-editor.jsp?clientServer=${encodeURI(
			`${window.location.protocol}//${window.location.host}`,
		)}`,
	loadStartupConfig: (): SmartCommsTypes['StartupConfigProperties'] => ({
		version: '2',
		editingMode: permissions[1] ? 'defaultMode' : 'readOnly',
		features: {
			enabled: [
				'UndoRedo',
				'ControlCodes',
				'TrackChanges',
				'SimpleFormatting',
				'TextAlignment',
				'BulletList',
				'NumberList',
				'ListIndentation',
				'Table',
				'InsertImage',
				'InsertLink',
				'InsertEquation',
				'InsertSharedContent',
				'InsertPageBreak',
				'InsertPageNumbering',
				'Footnotes',
				'Preview',
				'Document',
				'Review',
				'DataValues',
				'SpellCheck',
				'FindAndReplace',
				'StylePicker',
				'AddComment',
				'Save',
				'Signatures',
				'BordersAndShading',
				'PasteMode'
			],
			discretionary: ['DataValueDeleteGroupsDefaultToEditGroups'],
		},
		disableInlineEditing: false,
		openViewAfterLoad: null,
	}),
})

export const setUpSmartDx = () => {
	const smart: SmartCommsTypes = (global as unknown as { SmartComms: SmartCommsTypes }).SmartComms
	smart.DraftEditor.prototype.DRAFTEDITOR_CONTEXT = '/smartcomms/draft-editor'

	return smart
}

export const createEditor = (
	permissions: Perms,
	config: { clourdUrl: string; authUrl: string, url: string },
	smart: SmartCommsTypes,
) => smart.DraftEditor.create(smartDxConfig(permissions, config))
export const getV3 = (smart: { v3: SmartEditor }): SmartEditor => smart.v3

export const setTheme = (editor: SmartEditor, theme: string): SmartEditor =>
	Combinators.constant(editor, editor.setTheme(theme))
export const setCompareMode = (editor: SmartEditor, file1: string, file2: string): SmartEditor =>
	Combinators.constant(
		editor,
		editor.compareDraftTML(file1, file2, { useDualColourDifferences: false }),
	)
export const loadString = (editor: SmartEditor, file1: string): SmartEditor =>
	Combinators.constant(
		editor,
		editor.loadString(file1, { openInPreview: false, trackChanges: true }),
	)
export const exportFile = (editor: SmartEditor): SmartEditor =>
	Combinators.constant(editor, editor.saveToString())
export const setEditMode = (
	editor: SmartEditor,
	config: SmartCommsTypes['EditConfig'],
): SmartEditor => Combinators.constant(editor, editor.setEditMode(config))

export const EditorAnchor = ({ editorIsLoading }: { editorIsLoading: boolean }) => (
	<div style={{ flexGrow: 1, height: '85vh' }}>
		<div
			id="draftEditorPlaceHolder"
			style={{
				flexGrow: 1,
				height: '85vh',
				display: `${!editorIsLoading ? 'flex' : 'none'}`,
			}}
		/>
	</div>
)
