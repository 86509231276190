import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Http, GlobalContext as SharedGlobalContext } from '@sgmo/shared'

import { rapidToast } from '@/helpers/powerFullToast'

import { createToast } from '@/components/common/ToasterProvider'

import type { GlobalContext } from '@/types/global-context'
import type { DocumentInfo } from '@/types/documentInfo'
import type { DocumentCompare } from '@/types/documentCompare'

const preLoad = rapidToast('Document')

const isSettlementRelatedDocument = (docType: string) =>
	// settlement related documents : Prematching, Fixing, Payment, Payment remainder
	// https://sgithub.fr.world.socgen/XOne/XOne/blob/PROD60/src/Framework/TOne/Correspondence/Data/Enums/DocumentType.cs
	['PreMatching', 'FlowAdvice', 'FlowCorrespondence', 'Fixing', 'Payment', 'PaymentReminder']
		.map(t => t.toLowerCase())
		.includes(docType.toLowerCase())

export const DocumentService = {
	getDocument: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		tradeId: string,
		docId: string,
		docType: string,
	): Promise<DocumentInfo> =>
		Http.get(`Documents/${tradeId}/${docType}/${docId}`, [], abortSignal, globalContext).then(
			async response => {
				const toast = preLoad('getDocument')
				if (!response.ok) {
					toast(response.status)
					throw response
				}
				const doc = await response.json()
				if (doc.originalContent === null && doc.workingContent === null) {
					createToast({
						level: 'danger',
						text: <FormattedMessage id="Document.getDocument.null" />,
						icon: 'error_outline',
					})
					throw new Error('nulled file')
				}
				toast(response.status)
				return doc
			},
		),

	getReview: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		tradeId: string,
		docType: string,
	): Promise<DocumentInfo> =>
		Http.get(`ReviewCases/${tradeId}/${docType}/`, [], abortSignal, globalContext).then(
			async response => {
				const toast = preLoad('getReview')
				if (!response.ok) {
					toast(response.status)
					throw response
				}
				const doc = await response.json()
				if (doc.originalContent === null && doc.workingContent === null) {
					createToast({
						level: 'danger',
						text: <FormattedMessage id="Document.getReview.null" />,
						icon: 'error_outline',
					})
					throw new Error('nulled file')
				}
				toast(response.status)
				return doc
			},
		),

	getDocumentLang: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		tradeId: string,
		lang: string,
	): Promise<DocumentInfo> =>
		Http.get(`Documents/${tradeId}/${lang}`, [], abortSignal, globalContext).then(
			async response => {
				const toast = preLoad('getDocument')
				if (!response.ok) {
					toast(response.status)
					throw response
				}
				const doc = await response.json()
				if (doc.originalContent === null && doc.workingContent === null) {
					createToast({
						level: 'danger',
						text: <FormattedMessage id="Document.getDocument.null" />,
						icon: 'error_outline',
					})
					throw new Error('nulled file')
				}
				toast(response.status)
				return doc
			},
		),

	getDocumenCompare: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		tradeRef: string,
		docType: string,
	): Promise<DocumentCompare> =>
		Http.get(`Documents/Compare/${tradeRef}/${docType}`, [], abortSignal, globalContext).then(
			async response => {
				const toast = preLoad('getDocument')
				if (!response.ok) {
					toast(response.status)
					throw response
				}
				const doc = await response.json()
				toast(response.status)
				return doc
			},
		),

	putDocument: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		tradeId: string,
		documentType: string,
		id: string,
		input: {
			isPreTrade: boolean
			assetClass: string
			userBdrInitials: string
			workingDocument: string
		},
	): Promise<number> =>
		Http.put(
			`Documents/${tradeId}/${documentType}/${id}`,
			[],
			input,
			abortSignal,
			globalContext,
		).then(response => {
			if (!response.ok) {
				// error(`${response.status}`)
			}

			return response.status
		}),

	putSettlement: async (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		assetClass: string,
		docId: string,
		actionName: 'Store' | 'Send' | 'Chasing' | 'CancelChasing' | 'Validate',
		docType: string,
		tradeRef: string,
		actionComment: string,
		isPreTrade?: boolean,
		userBdrInitials?: string,
		UserProfile?: string,
	): Promise<number> => {
		let query: Promise<Response>

		if (isSettlementRelatedDocument(docType)) {
			query = Http.put(
				`Settlement/${assetClass}/${docId}/${actionName}`,
				[],
				{
					actionComment,
				},
				abortSignal,
				globalContext,
			)
		} else {
			query = Http.put(
				`Documents/${tradeRef}/${docType}/${docId}/DocumentActions/${actionName}`,
				[],
				{
					isPreTrade: isPreTrade ?? true,
					assetClass,
					actionComment,
					userBdrInitials,
					UserProfile,
				},
				abortSignal,
				globalContext,
			)
		}

		try {
			return (await query).status
		} catch (e: unknown) {
			const checkError = (error: unknown): error is { status: number } =>
				(error as { status: number }).status !== undefined

			if (checkError(e)) return e.status
			return -1
		}
	},

	previewDocument: (
		abortSignal: AbortSignal,
		globalContext: GlobalContext,
		documentType: string,
		contentXML: string,
	): Promise<Blob> =>
		Http.post(
			`Documents/${documentType}/preview`,
			[],
			contentXML,
			abortSignal,
			globalContext as SharedGlobalContext,
		).then(response => {
			const toast = preLoad('putDocument')
			toast(response.status)
			if (!response.ok) {
				throw response
			}

			return response.blob()
		}),
}
