import React from 'react'
import { HelpCenter } from '@/components/sgwt/HelpCenter'
import { MiniFooter } from '@/components/sgwt/MiniFooter'

export function Footer() {
	return (
		<footer
			style={{
				position: 'fixed',
				bottom: 0,
				width: '100vw',
				backgroundColor: 'var(--bg-lvl1)',
				paddingRight: 5,
			}}
		>
			<HelpCenter />
			<MiniFooter />
		</footer>
	)
}
