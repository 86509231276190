import { useIntl } from 'react-intl'

export const AccountCenter = () => {
	const intl = useIntl()
	return (
		<sgwt-account-center
			available-languages="fr,en"
			authentication="sg-connect-v2"
			mode="sg-markets"
			language={intl.locale}
			producer-code="sgm_tradedoc"
			//environment={env} @TODO to fix
		/>
	)
}
