import React from 'react'

export interface IConditionalProps {
	condition: boolean
	fallback?: React.ReactNode
	children: React.ReactNode | React.ReactNode[]
}

export const Conditional: React.FC<IConditionalProps> = ({ condition, fallback, children }) => {
	if (!condition) {
		return <>{fallback ?? null}</>
	}

	return <>{children}</>
}
