import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfig } from '@/config/config';
import type { DocumentInfo } from '@/types/documentInfo';
import { DocumentCompare } from '@/types/documentCompare';
import { DocumentSavePayload, DocumentSettlementPayload } from './document.model';
import { getCurrentContext } from '@/context/UserContext';
import { UIState } from './ui.slice';

export const isSettlementRelatedDocument = (documentType: string) =>
  // settlement related documents : Prematching, Fixing, Payment, Payment remainder
  // https://sgithub.fr.world.socgen/XOne/XOne/blob/PROD60/src/Framework/TOne/Correspondence/Data/Enums/DocumentType.cs
  ['PreMatching', 'FlowAdvice', 'FlowCorrespondence', 'Fixing', 'Payment', 'PaymentReminder']
    .map((t) => t.toLowerCase())
    .includes(documentType.toLowerCase());

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api.endpoint,
    prepareHeaders: (
      headers: Headers,
      { getState }: Pick<BaseQueryApi, 'getState' | 'type' | 'extra' | 'endpoint' | 'forced'>,
    ) => {
      const { ui } = getState() as { ui: UIState };
      const authHeader = getCurrentContext().sgConnect?.getAuthorizationHeader();
      const { clientScopeCode } = getCurrentContext();

      if (ui.origin.isKB) {
        headers.set('X-Client-Scope', 'KB');
      } else {
        headers.set('X-Client-Scope', clientScopeCode);
      }

      headers.set('Authorization', authHeader as string);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDocument: builder.query<
      DocumentInfo,
      {
        tradeReference: string;
        documentId?: string;
        documentType: string;
        isXone: boolean;
        silent?: boolean;
      }
    >({
      query: ({ tradeReference, documentId, documentType, isXone }) => ({
        url:
          documentId !== 'null' || !isXone
            ? `${isXone ? '' : 'Octane/'}${
                documentType === 'MRB' ? 'MRB' : 'Documents'
              }/${tradeReference}/${documentType}/${documentId}`
            : `ReviewCases/${tradeReference}/${documentType}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getSgDocDocument: builder.query<
      string,
      {
        documentId?: string;
      }
    >({
      query: ({ documentId }) => ({
        url: `SgDocs/Documents/${documentId}`,
        method: 'GET',
        responseHandler: (response) => response.text(),
      }),
    }),

    getChasing: builder.query<
      DocumentInfo,
      {
        documentType: string;
        codent: string;
        codtrs: number;
        chasing: string;
        chasingDate: string;
      }
    >({
      query: ({ documentType, codent, codtrs, chasing, chasingDate }) => ({
        url: `Octane/Chasing/${documentType}/${codent}/${codtrs}/${chasing}/${chasingDate}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),

    getDocumentLang: builder.query<
      DocumentInfo,
      {
        tradeReference: string;
        language: string;
        silent?: boolean;
      }
    >({
      query: ({ tradeReference, language }) => ({
        url: `Documents/${tradeReference}/${language}`,
        method: 'GET',
      }),
    }),

    getDocumentCompare: builder.query<
      DocumentCompare,
      {
        tradeReference: string;
        documentType: string;
        silent?: boolean;
      }
    >({
      query: ({ tradeReference, documentType }) => ({
        url: `Documents/Compare/${tradeReference}/${documentType}`,
        method: 'GET',
      }),
    }),

    putDocument: builder.query<DocumentCompare, DocumentSavePayload>({
      query: ({
        tradeReference,
        documentType,
        documentId,
        assetClass,
        isPreTrade,
        userBdrInitials,
        UserProfile,
        workingDocument,
      }) => ({
        url: `Documents/${tradeReference}/${documentType}/${documentId}`,
        method: 'PUT',
        body: {
          isPreTrade: isPreTrade ?? true,
          assetClass,
          userBdrInitials,
          UserProfile,
          workingDocument: btoa(unescape(encodeURIComponent(workingDocument))),
        },
      }),
    }),

    putSettlement: builder.query<DocumentCompare, DocumentSettlementPayload>({
      query: ({
        tradeReference,
        documentType,
        documentId,
        assetClass,
        actionComment,
        isPreTrade,
        userBdrInitials,
        UserProfile,
        actionName,
      }) => ({
        url: isSettlementRelatedDocument(documentType)
          ? `Settlement/${assetClass}/${documentId}/${actionName}`
          : `Documents/${tradeReference}/${documentType}/${documentId}/DocumentActions/${actionName}`,
        method: 'PUT',
        body: {
          isPreTrade: isPreTrade ?? true,
          assetClass,
          actionComment,
          userBdrInitials,
          UserProfile,
        },
      }),
    }),

    saveOctane: builder.query<
      void,
      {
        tradeReference: string;
        documentType: string;
        documentId: string;
        assetClass?: string;
        userBdrInitials?: string;
        workingDocument: string;
        userProfile?: string;
        actionComment?: string;
        action: 'Save' | 'SaveToValidate' | 'Send' | 'SendEmail';
      }
    >({
      query: ({
        tradeReference,
        documentType,
        documentId,
        assetClass,
        userBdrInitials,
        workingDocument,
        userProfile,
        actionComment,
        action,
      }) => ({
        url: `Octane/${action}/${tradeReference}/${documentType}/${documentId}`,
        method: 'POST',
        body: {
          assetClass,
          userBdrInitials,
          workingDocument: btoa(unescape(encodeURIComponent(workingDocument))),
          userProfile,
          actionComment,
        },
      }),
    }),

    saveChasing: builder.query<
      void,
      {
        documentType: string;
        codent: string;
        codtrs: number;
        chasing: string;
        assetClass?: string;
        userBdrInitials?: string;
        workingDocument: string;
      }
    >({
      query: ({ documentType, codent, codtrs, chasing, assetClass, userBdrInitials, workingDocument }) => ({
        url: `Octane/Chasing/save/${documentType}/${codent}/${codtrs}/${chasing}`,
        method: 'POST',
        body: {
          assetClass,
          userBdrInitials,
          workingDocument: btoa(unescape(encodeURIComponent(workingDocument))),
        },
      }),
    }),

    sendChasing: builder.query<
      void,
      {
        documentType: string;
        codent: string;
        codtrs: number;
        chasing: string;
        chasingDate: string;
        client: number;
        assetClass?: string;
        userBdrInitials?: string;
        userProfile: string;
        actionComment: string;
        workingDocument: string;
        attachedFiles: {
          key: string;
          value: ArrayBuffer;
        }[];
      }
    >({
      query: ({
        documentType,
        codent,
        codtrs,
        chasing,
        chasingDate,
        client,
        assetClass,
        userBdrInitials,
        userProfile,
        actionComment,
        workingDocument,
        attachedFiles,
      }) => ({
        url: `Octane/Chasing/send/${documentType}/${codent}/${codtrs}/${chasing}/${chasingDate}/${client}`,
        method: 'POST',
        body: {
          assetClass,
          userBdrInitials,
          userProfile,
          actionComment,
          workingDocument: btoa(unescape(encodeURIComponent(workingDocument))),
          attachedFiles,
        },
      }),
    }),

    previewDocument: builder.query<
      string,
      {
        workingDocument: string;
      }
    >({
      query: ({ workingDocument }) => ({
        url: `Documents/Confirmation/preview`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: JSON.stringify(workingDocument),
      }),
    }),
  }),
});

export const {
  useGetDocumentQuery,
  useGetSgDocDocumentQuery,
  useLazyGetDocumentQuery,
  useLazyGetDocumentLangQuery,
  useLazyGetChasingQuery,
  useGetChasingQuery,
  useLazyGetDocumentCompareQuery,
  useLazyPutDocumentQuery,
  useLazyPutSettlementQuery,
  useLazySaveOctaneQuery,
  useLazySaveChasingQuery,
  useLazySendChasingQuery,
  useLazyPreviewDocumentQuery,
} = documentApi;
