/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { String } from '@bhc/ts-tools';

import type { SmartCommsTypes, SmartEditor } from '@/types/SmartCommsApi';
import type { RootState } from './store';

export type EditorState = {
  editor?: SmartEditor;
  currentConfig?: SmartCommsTypes['EditConfig'];
  currentContent?: string;
};

const initialState: EditorState = {};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditor: (state: EditorState, action: PayloadAction<SmartEditor | undefined>) => {
      state.editor = action.payload;
    },
    setCurrentConfig: (state: EditorState, action: PayloadAction<SmartCommsTypes['EditConfig']>) => {
      state.currentConfig = action.payload;
      state.editor?.setEditMode(action.payload);
    },
    setCurrentContent: (state: EditorState, action: PayloadAction<string>) => {
      try {
        const content = String.trim(decodeURIComponent(escape(atob(action.payload))));
        state.currentContent = content;
        state.editor?.loadString(content, { openInPreview: false, trackChanges: true });
      } catch (e) {
        const content = String.trim(atob(action.payload));
        state.currentContent = content;
        state.editor?.loadString(content, { openInPreview: false, trackChanges: true });
      }
    },
    setCurrentSgDocContent: (state: EditorState, action: PayloadAction<string>) => {
      state.currentConfig = { mode: 'readOnly' };
      state.currentContent = action.payload;
      state.editor?.loadString(action.payload, { openInPreview: false, trackChanges: false });
    },
    setCompareState: (state: EditorState, action: PayloadAction<{ contentSrc: string; contentDest: string }>) => {
      state.currentConfig = { mode: 'compareMode' };
      state.editor?.setEditMode({ mode: 'compareMode' });
      state.editor?.compareDraftTML(action.payload.contentSrc, action.payload.contentDest, {
        useDualColourDifferences: false,
      });
    },
  },
});

export const { setEditor, setCurrentConfig, setCurrentContent, setCompareState, setCurrentSgDocContent } =
  editorSlice.actions;

export const selectEditorState = (state: RootState) => state.editor;
export const selectEditor = (state: RootState) => state.editor.editor;
export const selectContent = (state: RootState) => state.editor.currentContent;
export const selectCurrentMode = (state: RootState) => state.editor.currentConfig?.mode;
