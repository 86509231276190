import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from '@/components/common/Modals/Modal'
import { modalOpenState, selectModal } from '@/store/ui.slice'

export const ConcurencyModal = () => {
	const open = useSelector(selectModal('Concurency'))
	const dispatch = useDispatch()

	return (
		<Modal open={open}>
			<div className="d-flex p-5 border bg-lvl2">
				<div className="modal-dialog position-relative" style={{ display: 'block' }}>
					<div className="modal-content shadow-max border border-info">
						<div className="modal-header">
							<div className="d-flex flex-column w-100">
								<div
									className="rounded-circle bg-info-alpha-md d-flex align-items-center justify-content-center mb-4"
									style={{ width: '3rem', height: '3rem' }}
								>
									<em className="icon icon-md text-info line-height-1">
										info_outline
									</em>
								</div>
								<h3 className="modal-title text-info mb-2 pt-0">
									<FormattedMessage id="modal.concurrency.title" />
								</h3>
								<p className="text-large text-secondary m-0">
									<FormattedMessage id="modal.concurrency.secondary" />
								</p>
							</div>
						</div>
						<div className="modal-body">
							<FormattedMessage
								id="modal.concurrency.text"
								values={{
									username: 'test',
									userlogin: 'test',
									date: format(new Date(Date.now()), 'dd MMM y'),
									time: format(new Date(Date.now()), 'H:mm O'),
								}}
							/>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-lg btn-info"
								data-dismiss="modal"
								onClick={() =>
									dispatch(
										modalOpenState({ modalId: 'Concurency', openState: false }),
									)
								}
							>
								<FormattedMessage id="modal.concurrency.ok" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
