import React from 'react'

interface Sgb4SpinnerProps {
	size?: 'xs' | 'sm' | 'md'
}

function Sgb4SpinnerRaw({ size = 'md' }: Sgb4SpinnerProps): JSX.Element {
	const sizeClass = size === 'md' ? '' : `spinner-grow-${size}`
	return (
		<div className={`spinner-grow ${sizeClass}`} role="status">
			Loading...
		</div>
	)
}

export const Sgb4Spinner = React.memo(Sgb4SpinnerRaw)
