import React from 'react'

export const MiniFooter = () => (
	<sgwt-mini-footer
		type="compact"
		design="light"
		mode="sg-markets"
		contact-us-by-help-center="sgwt-help-center"
	/>
)
