import React from 'react'

import { LeftHeader } from './LeftHeader'
import { RightHeader } from '../common/RightHeader'

export const EditorHeader = () => (
	<>
		<LeftHeader />
		<RightHeader />
	</>
)
